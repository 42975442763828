<template>
  <div class="notice_list" v-wechat-title="title">
    <!-- 公告列表 -->
    <div class="list_title">{{project_name}}公告列表</div>
    <div class="notice_item" v-for="(item,index) in notice_list" :key="index">
      <div class="notice_item_text" @click="goNoticeInfo(item)">
        <div class="title">{{item.title}}</div>
        <div class="date">{{resolveDate(item)}}</div>
      </div>
      <div class="notice_item_img">
        <van-image width="123" height="70" :src="item.image"/>
      </div>
    </div>
  </div>  
</template>

<script>
import { Toast } from 'vant';
import { getProjetNotification }  from '@/api/api';
import {  mapState,mapMutations } from 'vuex'
import { resolveDateStr } from '@/utils/utils.js'

export default {
  data(){
    return {
      title:'',
      notice_list: [], //公告列表
      project_name:'',
    }
  },
  computed: {
    ...mapState(['projectInfo']),
  },
  created(){
    this.project_name=this.projectInfo.name;
    this.title=this.projectInfo.name;
    this.init();
  },
  methods: {
    ...mapMutations(['setNoticeInfo']),
    async init(){
      await getProjetNotification({
        params: {
          project_id:this.projectInfo.id,
        }
      }).then(res => {
        console.log("公告列表",res)
        let list =[]
        for(let i of res) {
          let temp=i.send_date
          let now=new Date();
          temp = temp.substring(0,19);    
          temp = temp.replace(/-/g,'/'); 
          let date=new Date(temp).getTime();
          if(now-date>0) list.push(i)
        }
        console.log("list",list)
        this.notice_list=list
      }).catch(err => {
        Toast(err.response.data.message)
      })
    },
    // 获取公告列表
    goNoticeInfo(item){
      this.setNoticeInfo(item)
      this.$router.push({
        path:"/notice/notice_info",
      })
    },
    resolveDate(item){
      let date=resolveDateStr(item.send_date)
      console.log("date:",date)
      return date
    }
  }
}
</script>

<style lang="scss" scoped>
.notice_list{
  padding: 15px;
  .list_title{
    text-align: left;
    font-size: 20px;
    color: #101010;
    margin-bottom: 10px;
  }
  .notice_item{
    // background: #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-top: 1px solid rgba(187, 187, 187, 0.8);
    .notice_item_text{
      text-align: left;
      width: 55%;
      .title{
        font-family: PingFangSC-regular;
        font-size: 14px;
        color: #101010;
        margin-bottom: 7px;
      }
      .date{
        color: #626262;
        font-size: 12px;
      }
    }
    .notice_item_img{
      display: flex;
    }
  }
}
</style>